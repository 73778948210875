



import PostsContent from '../config/portfolio';
import ReactMarkdown from 'react-markdown';
import {useEffect} from "react"
import { useNavigate} from "react-router-dom";



type Props = {
    type: string;
  };




const Posts = (props: Props) => {
    let navigate = useNavigate();

    const {advertise, content, heat, stress} = PostsContent
    let posts: string[] = []
    switch (props.type) {
        case "advertise":
            posts = advertise
            break;
        case "content":
            posts = content
            break;
        case "heat":
            posts = heat
            break;
        case "stress":
            posts = stress
            break;                                                                        
    }
    let mobile = false
    if (window.matchMedia("(max-width: 899px)").matches) {
        mobile = true
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    const back = ()=>{
        navigate(-1)
    }
    return (
        <div>
          <div className="mt-3 sm:mt-0 sm:ml-3" style={{width: "100px", height: "50px", cursor: "pointer"}} onClick={back}>
            <a              
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md border-primary text-secondary bg-background md:py-4 md:text-lg md:px-10`}
            >
              Назад
            </a>
          </div>
        <div style={{position: "absolute", left: "50%", transform: "translate(-50%, 0%)"}}>
            {posts.map((post, index) => (
                <div style={{borderRadius: "30px", width: mobile ? "100%" : "900px", padding: "10px", backgroundColor: "white"}}>
                    <div>Пост {index+1} ----------</div>
                    <ReactMarkdown 	className="markdown"
						children={post}
					/>
                </div>
            ))}
        </div>
        </div>
    )
}

export default Posts