

type PostsContentType = {
    advertise: string[];
    heat: string[];
    content: string[];
    stress: string[];
  };

const PostsContent: PostsContentType = {
    advertise: [`
### Увеличить конверсию на 35% за неделю и заставить конкурентов глотать пыль

Две технологии, которые уже сейчас помогут привлечь и удержать еще больше клиентов, а также быстро увеличить конверсию: это чат-боты и автоворонки

Я маркетолог и ко мне обратился Влад, молодой предприниматель из Ростова, владелец цветочного магазина с доставкой цветов по всему городу. Ему хотелось продвигать свою услугу, но одним из его условий было - использовать самые современные методы.

Сначала я настроила ему чат-бота. Сейчас это делается легко, без необходимости в технических знаниях. Так, по взаимодействию чат-бота с клиентами мы быстро поняли с какими трудностями они сталкиваются и какие у них бывают запросы.

Кому-то нужно было подобрать букет по цветовой палитре и описанию. Кому-то нужно оставить записку, а кто-то хочет дополнительно подарить коробку конфет.

Всё разнообразие запросов можно легко обрабатывать в чат-боте автоматически, без привлечения консультантов. Это снижает себестоимость и увеличивает конверсию, которая выросла аж на 35% за неделю. 

Конкурировать с теми у кого есть чат-бот и настроена автоворонка становится всё тяжелее.

Потом Влад расширил ассортимент и мы сделали рассылку. И опять же сопроводили её сценарием как чат-бот должен реагировать на определенные вопросы покупателей. 

Результат не заставил себя долго ждать. 15% покупателей приобрели букеты из новой коллекции за первые три дня, мы сначала не верили своим глазам.

И подобных историй у меня накопилось уже довольно много.

Здравствуйте, я Ольга Шевчук, маркетолог с 7ми летним стажем, а также автор онлайн-курсов по современным подходам в маркетинге.

Я хочу поделиться с вами знаниями на бесплатном вебинаре "Чат-боты, автоворонки и рассылки. Что? Где? Зачем?"

Для понимания материала совсем не требуется никаких знаний, даже из области маркетинга. Рассказываю доступным языком о современных трендах.

За полтора часа вебинара вы узнаете:
- Что такое чат-боты и автоворонки
- Где и как их можно создавать и настраивать
- Зачем они нужны бизнесам и какие показатели можно увеличить с их помощью

Присоединяйся: <ссылка>

Спеши, количество мест ограничен
`,
`
### Уйти из найма и отправиться в путешествие. Ищешь удаленную работу?

Как тебе идея получить навык, который востребован сейчас и будет актуален в будущем?

Это настройка чат-ботов и автоворонок. Интересно? Еще как.

А что обычно требуется для этих ваших чат-ботов? Из чего состоит сама услуга?

Нужно просто зайти на сайт, который предоставляет возможность создавать и настраивать чат-боты. А потом кликнуть несколько раз и набрать пару текстов по довольно незамысловатой схеме. Всё, чат-бот готов! Клиент доволен и принимает новых покупателей в чате, а ты получаешь от него деньги. Уловил?

Несмотря на простоту, этот навык очень хорошо монетизируется, по 500-1000р за час работы.

Таких специалистов мало, а спрос на них растет. Успей стать профи с опытом, пока рынок еще не сформировался.

Я, Ольга Шевчук, опытный маркетолог, больше 7ми лет в профессии. Всегда стараюсь идти в ногу с новыми трендами, постоянно ищу самые перспективные технологии. Подготовила для вас вебинар "Чат-боты, автоворонки и рассылки. Что? Где? Зачем?"

Для понимания этой темы не нужно обладать техническими знаниями. Вебинар для вас будет абсолютно бесплатным.

В программе полуторачасового вебинара:
- Объясню что такое чат-боты и автоворонки на пальцах и понятным языком
- Покажу где есть простой инструмент для их создания и как их настраивать в пару кликов. Дам простую схему, с которой уже можно начать зарабатывать. С этим справятся даже школьники и пенсионеры
- Отвечу на вопросы: каким бизнесам подходит эта технология? И зачем она им нужна?

Мы стартуем завтра в 20:00. Успей зарегистрироваться, пока есть места: <ссылка>
`,
`
### Работаю на себя, настраиваю чат-ботов и зарабатываю от 1000 рублей в час

Я давно искала возможность сбежать от злого и требовательного начальника и работать независимо в интернете.

Но многие профессии оказывались либо скучными (таргетолог, SEO-оптимизатор, ...) или сложными (программист, системный администратор, ...).

Я по многу времени проводила в чатах и давно знала о существовании чат-ботов, но мне всегда казалось, что их разрабатывают умные программисты и это трудоемкий процесс.

Но однажды я наткнулась на сайт, который предлагал в два клика создать чат-бота и написать сценарий к нему. Неужели! Настолько просто? Так появился бот - ассистент, который помогал мне обрабатывать заявки на настройку чат-ботов и зарабатывать свои первые деньги на полученных знаниях.

Это так классно, когда ты предугадываешь намерения покупателей и созданный тобой бот зарабатывает деньги для твоего клиента. И все оказываются довольны: и покупатель, и клиент, и ты.

Интересно разобраться в теме?

Меня зовут, Ольга Шевчук и я хочу сделать довольными еще больше людей, поэтому я создала бесплатный вебинар "Чат-боты, автоворонки и рассылки. Что? Где? Зачем?"

На вебинаре за полтора часа вы:
- Узнаете что такое Чат-боты и автоворонки
- Увидите где можно создавать и настраивать Чат-ботов, а также ознакомитесь с простой схемой по привлечению покупателей с помощью автоворонок и рассылок  
- Разберетесь зачем чат-боты нужны бизнесам

Регистрируйтесь: <ссылка>

Торопитесь, количество мест ограничено
`
    ],
    heat: [
    `
### О том, что делать. Кто виноват мы итак знаем.

Осень 2018 года. Я возвращалась с нелюбимой работы. Сначала набитый людьми автобус, у всех унылые скучающие лица, и у меня тоже. То, что мы едем в такой тесноте не нравилось никому.

А что делать? На улице дождь и прогулка в такую погоду - не лучшее решение.

Когда шла от остановки домой, меня обрызгала машина. "Черт, будь проклят этот день! Будь проклята эта жизнь!" пронеслось у меня в голове.

А что делать? От брызг увернуться невозможно.

И вот после серой улицы я оказалась в уютном доме. Там мой парень принес продукты с магазина, мы приготовили поесть, поужинали и сели смотреть сериал. 

И тут поймала себя на мысли, что было бы замечательно вырезать эпизоды с работой, автобусом, улицей и вставить побольше домашнего уюта и времени проведенного с любимыми людьми. Сделать Delete одного и Ctrl-C Ctrl-V другого.

А что делать? 

У нас всегда есть выбор: идти вперед или стоять на месте. Искать пути решения своих проблем или оставаться в той жопе, куда угадили.

Так я начала изучать интернет-профессии. Их сейчас огромное количество. И я проделала огромный путь прежде чем пришла к настройке чат-ботов.

Пыталась освоить профессию в одиночку, а осваивать что-то одному - это значит каждый день после работы жертвовать крупицами своего личного времени и перегорать.

Набила столько шишек, что до сих пор в теле отголосками отдается боль. И все равно пришла к тому, что лучше приобрести чужой опыт и пройти курс. 

Теперь же я насыщена знаниями и опытом и сама хочу этим делиться с вами.

Ответьте в комментариях: пробовали ли осваивать интернет-профессии самостоятельно? Каких результатов добились?

А почему выбрала чат-боты? Об этом подробнее в следующем посте.    
    `,
`
### Один человек. Одна профессия.

Я перепробовала многое. Пробовала программировать, настраивать таргетинговую рекламу, училась графическому дизайну. И даже изучала работу с криптовалютой.

Но одно было скучно, другое сложно, третье просто не мое.

Много времени проводила в чатах. Общалась с друзьями, следила за новостями и за экспертами.

И вот у моей любимой сети тренажерных залов появился чат-бот. Раньше, чтобы узнать какое занятие будет завтра, мне приходилось переходить в браузер, заходить на сайт, искать расписание на сайте и форму для записи.

А теперь я могу просто кликнуть на кнопку, не выходя из чатов, и вот уже записалась на занятие. Это так удобно. И технологично. Кажется какой-то гений перенес функционал сайта в чат и сделал его более удобным.

Потом, когда искала информацию по SEO-оптимизации, случайно наткнулась на рекламу сайта по настройке чат-бота.

Моя первая реакция: т е чтобы настроить чат-бот не нужно обладать какими-то особыми знаниями? Серьезно? С этим справится даже пенсионер? А именно так было написано в рекламе.

Перешла по ссылке, изучила сайт, стала копать глубже и вот что обнаружила. Итак, почему чат-боты?

1. Эта услуга как никогда актуальна для десятков тысяч бизнесов. Потому что они хотят быть поближе к своим клиентам, а также хотят увеличивать конверсию и удерживать внимание клиентов.

Есть примеры, когда чат-бот становится основным источником продаж.

2. Настроить чат-бот - это разовая, быстрая услуга. Т е если ты её предлагаешь, ты выбираешь для себя нужное количество заказов, как сильно загрузить свое время и сколько денег заработать.

Это очень важно, потому что в жизни бывают периоды, когда тебе нужно больше свободного времени. Например, у тебя заболел ребенок или ты выгорел на работе и хочется просто побольше отдохнуть.

И их сменяют периоды когда тебе нужно больше денег. Например, ты хочешь на что-то накопить.

Когда ты можешь регулировать нагрузку, из любой жизненной ситуации ты выходишь победителем.

3. Ты можешь работать из дома. Тебе не нужно тратить свою жизнь на бесконечные пробки, сидеть в набитом людьми общественном транспорте. Тебе не нужно находиться на улице в непогоду.

Ты просто сидишь в уюте и успеваешь сделать все домашние дела без чувства постоянной погони.

4. Этому легко научиться. Тебе не нужно тратить кучу времени, пока ты не набьешь шишки во всех нужных местах и не проскачешь по всем обязательным граблям.

Я подготовила для тебя всю необходимую информацию, чтобы стартовать и начать зарабатывать с нуля. Курс по объему относительно маленький.

Только полезная информация, без нудного вождения вилами по воде. Просто и ясно.

Безусловно у настройки чат-ботов есть и минусы, но об этом в следующий раз.

А что вас привлекло в чат-ботах? Об этом пишите в комментариях.
`,
`
### Что-то большее

И вот я стала настраивать чат-ботов. Пошли первые клиенты. Первые заработанные деньги.

Я помню, как отпраздновала свои первые 100 000 рублей на чат-ботах. Вечер с любимым, шампанское и очень вкусная пицца на ужин.
Но постепенно все стало рутиной. Хотелось роста. Хотелось новых вызовов и ощущений. 

А также меня начало напрягать, что мне все время приходится искать клиентов. Иногда приходится навязываться, иногда тратить деньги на рекламу.

И в голове начал формироваться вопрос: а что еще я могу предложить? Да, умею настраивать чат-ботов, но что еще будет выделять меня на фоне таких же настройщиков?

Так, стала задавать больше вопросов заказчикам и выяснила, что попутно с появлением чат-бота бизнесмены изучают и сегментируют свою целевую аудиторию. Для тех, кто начинает - это непростая задача.

Также моим клиентам было нужно составлять тексты для рассылок и автоворонок. Да и вообще составлять автоворонку так, чтобы конверсия была максимальной.

Видела как они справляются с этими проблемами и училась на их ошибках. Удобно, правда?

Попутно общалась с маркетологами и перенимала практики и у них. И наконец начала расширять количество оказываемых услуг.

Теперь я также помогала с сегментированием целевой аудитории. И с помощью полученных знаний настраивала автоворонки, писала для них текст, а также занималась рассылками.

Теперь же предлагала не просто услугу, а целый пакет. И все элементы пакета дополняли друг друга.

На своем курсе рассказываю не только о том, как настраивать чат-боты. Также учу сегментировать целевую аудиторию, настраивать автоворонки и писать текст для рассылок. В результате, после обучения вы также сможете продавать сразу пакет услуг.

Это будет вам давать преимущества на рынке таких же настройщиков. Выгодно вас выделит на фоне других.

Зарегистрироваться на курс можно по ссылке: <ссылка>
`
    ],
    content: [
        `
### Кейс: чат-бот для арт-завода Доренберг

Внезапный звонок, энергичный молодой голос:

"Меня зовут Павел, ищу специалиста для настройки чат-бота. Бизнес - арт пространство. 300 мероприятий в течении года. Больше 60 компаний и магазинов. 2 Га благоустроенной территории. Работаем?"

"Конечно!"

Два дня работы и что теперь умеет чат-бот: 
- можно посмотреть расписание арт-выставок, сцен
- карту фуд-корта
- задать вопрос службе поддержки
- узнать стоимость аренды
- получить контакты руководства
- купить билет на мероприятие

Уже около 1000 пользователей. Красота. Пиши в комментариях, если хочешь больше кейсов.        
        `,
        `
### Как маркетологи обманывают наш мозг?

Мозг управляет нами или мы им? Оказывается он нами, так как количество решений которые мы принимаем на автомате - 95% от общего числа решений.

Это доказано учеными.

Маркетологи же давно задаются вопросом: как повлиять на мозг человека так, чтобы он стал покупателем их товара?

Чтобы ответить на этот вопрос используются разные датчики и исследования:

1. Например на людей вешают датчики, которые замеряют всплески магнитного поля в мозге при просмотре рекламы. Так удается понять какие аспекты рекламы нравятся, а что нужно изменить. Причем меняют не только видеоряд, но также и звуковое сопровождение.

Так, какое-то время в рекламе было популярно повторять одну и ту же фразу несколько раз, как будто её заело. Потому что выяснилось, что на людей это влияет особым образом.

2. Давно изучено влияние цветов на людей: красный цвет при первом знакомстве может не только привлекать внимание, но и подавлять. Черный – вызывать тоску, но в то же время подчеркивать элитарность. Белый – ассоциироваться с чистотой. Зеленый с природой и экологией.

3. Также известно, что люди в первую очередь обращают внимание на лица, особенно если на рекламном фото - ребенок. 

Поэтому если мы хотим, чтобы человек обратил внимание на наш текст, мы делаем фото ребенка, у которого лицо повернуто на наш текст, а не на читателя.

Мы это делаем, чтобы человеческий глаз сосредоточился на чтении, и не отвлекался на лицо.

Думаю суть ясна. Пользоваться знанием о мозге - вполне этично, это делают все крупные компании.

Если пост был полезен, отметьте это лайком.        
        `,
        `
### 3 коротких истории о том, как гениальные решения помогли изменить поведение людей!

И самое главное, во всех историях маркетологам даже не пришлось взывать к убеждениям.

1. В Лондоне решили провести кампанию по популяризации здорового питания и увеличить продажи свежих овощей. 

Сначала повесили плакаты с призывами «Питайся правильно, ешь больше фруктов и овощей!», но это дало результат всего лишь в 4%. 

Затем пришла интересная идея, а что если обычные тележки в супермаркетах разделить на две части, на одной из которых написать: «для овощей». В результате продажи овощей выросли на 60%. 

Это яркий пример того, что не стоит навязывать потребителю новые убеждения. Однако можно попробовать изменить его поведение.

2. В Швеции для того, чтобы стимулировать жителей не превышать скорость, заявили о лотерее. 


Участниками лотереи становились те водители, которые не нарушали правила, а призовой фонд составляли штрафы нарушителей. 


Это дало гораздо больший результат, чем призывы ездить со скоростью 60 километров в час, ведь все это и так знают, но поведение часто расходится с убеждениями.

3. В Бельгии решили повысить продажи продуктов в перерабатываемой упаковке. 


Для этого мусорные баки для не перерабатываемой упаковки стали делать маленького размера. Места в мусорке всегда не хватало, и потребители неосознанно перешли на товары в экологичной упаковке.

Эти примеры показывают как можно влиять не поведение, применив смекалку. Если было интересно, не забудь прожать лайк.
        
        `        
    ],
    stress: [
        `        
### Клиенты в вашем кармане 

В России каждый день открывается несколько тысяч новых бизнесов. 

В наш век всем бизнесом нужно присутствовать в интернете. И они понимают, что большая часть людей в сети пользуются мессенджерами. Поэтому логично обзавестись собственным чат-ботом. Настроить в нем автоворонку и рассылку.

Спрос на услугу по настройке чат-ботов колоссальный. Известные настройщики не успевают обрабатывать все заказы. На рынке хватит места для всех, он еще до конца не сформирован.

Как искать клиентов?

1. Вы можете искать бизнесы без чат-ботов и писать им с предложением оказать услугу. Если вы грамотно объясните как это им поможет, скорее всего они сделают заказ. Как грамотно расписать ваше предложения, а также как найти кому писать - я расскажу на курсе.

2. Вы можете создать свой чат-бот и принимать на него заказы. При необходимости можно дать небольшую рекламу, сейчас это делается не сложно, дешево и реклама покажется только вашим потенциальным клиентам.

3. Существуют биржи труда, где заказчики выставляют свое техническое задание, а вы можете откликнуться и забрать заказ.

Если вы боитесь что кто-то может вас обмануть, есть простая схему, которую использует большинство фрилансеров и которая исключает обман: просто берите половину суммы заказа авансом. 

Еще большее количество лайфхаков по поиску клиентов расскажу на курсе. Например, вы также узнаете как общаться с клиентами, как их проверять и как понять с кем выгоднее иметь дело.

Регистрируйтесь: <ссылка на курс>
`,
`
### Чат-боты на раз два три. Просто повтори

Когда ты ничего не знаешь о какой-либо теме, тебе все кажется очень сложным. Это искажение восприятия.

При этом часто от знания и понимания тебя может отделать всего пара минут видео. Всего пара минут и для тебя открыт новый мир.

Кажется, что чат-боты - это о программировании или о системном администрировании. О чем-то таком сложном, что невозможно освоить. А это не так.

Есть простая схема, как создать и настроить чат-бота. Это как создание персонажа в игре.

Ты просто заходишь на сайт, кликаешь два раза мышкой и чат-бот создан. Потом ты можешь воспользоваться готовым сценарием, если твой клиент - это типовой бизнес. Например это цветочный магазин с доставкой или ателье. Тогда тебе понадобится просто еще пара кликов и настройка готова.

Если готового сценария нет, то тебе нужно будет ввести пару текстов с заготовленными ответами и кнопками. Это очень увлекательно, как игра, где твоя задача сделать покупателя довольным.

Главное! У тебя будет готовая схема: что и как надо делать. Тебе не придется ничего сочинять. Просто делаешь все по алгоритму, который я дам, и все счастливы.

Все тонкости, лайфхаки, чит-коды и схемы я дам на курсе по настройке чат-ботов.

Присоединяйся: <ссылка>
`         
    ]
}

export default PostsContent