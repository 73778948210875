import React from 'react';

import config from '../config/index.json';

const MainHero = () => {
  const { mainHero } = config;
  return (
    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 sm:mt-16 2xl:mt-28">
      <div className="sm:text-center xl:text-center 2xl:text-left" style={{zIndex: 900, position: "relative"}}>
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl sm:text-6xl">
          <span className="block">{mainHero.title}</span>{' '}
          <span className={`block text-primary 2xl:inline`}>
            {mainHero.subtitle}
          </span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto sm:mt-5 sm:text-xl">
          {mainHero.description}
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center ">
          <div className="rounded-md shadow">
            <a
              href={mainHero.primaryAction.href}
              className={`w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-background bg-primary hover:bg-border hover:text-primary 2xl:py-4 2xl:text-lg 2xl:px-10`}
            >
              {mainHero.primaryAction.text}
            </a>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
          </div>
        </div>
      </div>
    </main>
  );
};

export default MainHero;
