import React from 'react';

import config from '../config/index.json';

const MainHeroImage = () => {
  const { mainHero } = config;
  return (
    <div className="2xl:absolute 2xl:inset-y-0 2xl:right-0 2xl:w-1/2">
      <img
        className="h-full w-full object-cover sm:h-full md:h-full 2xl:w-full 2xl:h-full"
        src={mainHero.img}
        alt="happy team image"
      />
    </div>
  );
};

export default MainHeroImage;
