import React from 'react';


import Home from './pages/home';
import Posts from './pages/posts';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom"


const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home></Home>}/>
        <Route path='/adposts' element={<Posts type="advertise"></Posts>}/>
        <Route path='/content' element={<Posts type="content"></Posts>}/>
        <Route path='/stress' element={<Posts type="stress"></Posts>}/>
        <Route path='/heat' element={<Posts type="heat"></Posts>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
